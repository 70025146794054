import { Section, SectionBody, SectionHeader } from "./section";
import { CustomCarousel } from "../../common/carousel";

const carouselItems = [
  {
    title: "Raised Floor",
    description: "TODO: Leading raised floor system specialists",
    backgroundImage: "/raised-floor.webp", // specify background image for this item
  },
  {
    title: "Operable Wall",
    description: "TODO: Leading Operable Wall system specialists",
    backgroundImage: "/operable-wall.webp", // specify background image for this item
  },
  {
    title: "Structural Ceiling",
    description: "TODO: Leading structural ceiling specialists",
    backgroundImage: "/structural-ceiling.webp", // specify background image for this item
  },
];

export function OurProducts() {
  return (
    <Section id={`our-products`} additionalClassNames={["section-even"]}>
      <SectionHeader>Our Products</SectionHeader>
      <SectionBody>
        <CustomCarousel items={carouselItems} />
      </SectionBody>
    </Section>
  );
}

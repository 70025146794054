import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { batchItems } from "../utils/batchItems";

const pageSize = 3;

interface CarouselItem {
  title: string;
  description: string;
  backgroundImage: string;
}

interface CarouselProps {
  items: CarouselItem[];
}

export function CustomCarousel({ items }: CarouselProps) {
  const isSmallScreen = useMediaQuery("(max-width:899px)");

  if (isSmallScreen) {
    return (
      <Carousel
        indicators={false}
        animation={"slide"}
        sx={{ width: "30rem", height: "30rem" }}
        navButtonsAlwaysVisible={true}
      >
        {items.map((item, index) => (
          <ProductCard
            index={index}
            backgroundImage={item.backgroundImage}
            title={item.title}
            description={item.description}
          />
        ))}
      </Carousel>
    );
  }

  const pages: CarouselItem[][] = batchItems(items, pageSize);
  pages[pages.length - 1] = items.slice(-pageSize);

  return (
    <Carousel
      autoPlay={false}
      animation={"fade"}
      sx={{ height: "30rem", width: "100%" }}
      navButtonsAlwaysVisible={pages.length > 1}
      navButtonsAlwaysInvisible={pages.length <= 1}
      cycleNavigation={false}
      swipe={false}
      indicators={true}
    >
      {pages.map((items) => (
        <CarouselPage items={items} />
      ))}
    </Carousel>
  );
}

interface ProductCardProps {
  index: number;
  backgroundImage: string;
  title: string;
  description: string;
}

function ProductCard({
  index,
  backgroundImage,
  title,
  description,
}: ProductCardProps) {
  return (
    <Card
      key={`carousel-card-${index}`}
      sx={{
        transition: "transform .2s, box-shadow 1s",
        "&:hover": {
          transform: "scale(1.02) perspective(0px)",
          "box-shadow": "0 2px 2px rgba(0,0,0,.7)",
        },
      }}
    >
      <CardActionArea>
        <CardMedia image={backgroundImage} sx={{ height: "30rem" }} />
        <CardContent
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            color: "#ffffff",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity here (0.7 for 70%)
            backdropFilter: "blur(5px)", // Optional: Add a blur effect
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

interface CarouselPageProps {
  items: CarouselItem[];
}

function CarouselPage({ items }: CarouselPageProps) {
  return (
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid item xs={4} key={index}>
          <ProductCard
            index={index}
            backgroundImage={item.backgroundImage}
            title={item.title}
            description={item.description}
          />
        </Grid>
      ))}
    </Grid>
  );
}

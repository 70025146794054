import { Container, Grid, Paper, Typography } from "@mui/material";
import React, { ReactNode } from "react";

import "./section.scss";
import Box from "@mui/material/Box";

interface SectionProps {
  id: string;
  children: ReactNode;
  additionalClassNames?: string[];
}

export function Section({ id, additionalClassNames, children }: SectionProps) {
  return (
    <Box
      id={id}
      className={[`section`, ...(additionalClassNames ?? [])].join(" ")}
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {children}
      </Container>
    </Box>
  );
}

interface SectionHeaderProps {
  children: ReactNode;
  classNames?: string[];
}

export function SectionHeader({
  children,
  classNames = [],
}: SectionHeaderProps) {
  return (
    <Typography
      component={"h1"}
      className={[`section-header`, ...classNames].join(" ")}
      sx={{
        fontFamily: "RobotoSlab, serif",
        fontSize: "1.75rem",
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      {children}
    </Typography>
  );
}

interface SectionBodyProps extends SectionHeaderProps {}

export function SectionBody({ children, classNames = [] }: SectionBodyProps) {
  return (
    <Box className={[`section-body`, ...classNames].join(" ")}>{children}</Box>
  );
}

interface TempSectionProps {
  id: number;
}

export function TempSection({ id }: TempSectionProps) {
  return (
    <Box
      id={`section-${id}`}
      className={`section ${getSectionBackground(id)}`}
      sx={{ height: "30rem" }}
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {/* Content Section */}
        <Grid container spacing={3}>
          {/* Column 1 */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h5" gutterBottom>
                Our Mission
              </Typography>
              <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Typography>
            </Paper>
          </Grid>

          {/* Column 2 */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h5" gutterBottom>
                Our Services
              </Typography>
              <Typography variant="body1">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function getSectionBackground(id: number) {
  return id % 2 ? "section-odd" : "section-even";
}

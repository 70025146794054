import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff", // Blue color
    },
    secondary: {
      main: "#2196f3", // White color
    },
  },
});

export default theme;

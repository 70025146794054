import {
  AppBar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { MENU_ITEMS, NAV_ITEMS } from "./const";
import { HeaderItemLink } from "./headerItemLink";
import { NavigationDrawer } from "./navigationDrawer";

export function Index() {
  const isSmallScreen = useMediaQuery("(max-width:899px)");

  const {
    anchorEl: productMenuAnchor,
    handleMenuClick: onProductMenuClick,
    handleClose: onProductMenuClose,
  } = useMenu();

  const { isDrawerOpen, toggleDrawer, closeDrawer } = useDrawer();

  return (
    <>
      <AppBar component="nav">
        <Toolbar>
          <Grid container justifyContent="center" spacing={2}>
            <Grid
              item
              xs={12}
              md={24}
              lg={6}
              display="flex"
              alignItems="center"
              justifyContent={isSmallScreen ? "center" : "space-between"}
            >
              <img
                src="/hykw-banner.png"
                alt="Logo"
                style={{ height: "50px" }}
              />
              <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
                <HeaderItemLink item={MENU_ITEMS.HOME} />
                <HeaderItemLink
                  item={MENU_ITEMS.PRODUCTS}
                  onClick={onProductMenuClick}
                />
                <Menu
                  anchorEl={productMenuAnchor}
                  open={Boolean(productMenuAnchor)}
                  onClose={onProductMenuClose}
                >
                  {NAV_ITEMS.Products!.map((subItem, index) => (
                    <MenuItem key={index} onClick={onProductMenuClose}>
                      {subItem}
                    </MenuItem>
                  ))}
                </Menu>
                <HeaderItemLink item={MENU_ITEMS.PROJECTS} />
                <HeaderItemLink item={MENU_ITEMS.CONTACT} />
              </Box>
            </Grid>
          </Grid>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{ mr: 2, display: { md: "none" }, marginRight: 0 }}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <NavigationDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        closeDrawer={closeDrawer}
      />
    </>
  );
}

function useMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return { anchorEl, handleMenuClick, handleClose };
}

function useDrawer() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return { toggleDrawer, isDrawerOpen, closeDrawer };
}

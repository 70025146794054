import React from "react";
import "./App.css";
import { Index } from "./components/header";
import { Footer } from "./components/footer";
import { Home } from "./components/pages/home";
import Toolbar from "@mui/material/Toolbar";

import "./assets/fonts/robotoSlab.css";

function App() {
  return (
    <div className="App">
      <Index />
      {/* Toolbar needed to act as a spacer for the rest of the page */}
      <Toolbar />
      <Home />
      <Footer />
    </div>
  );
}

export default App;

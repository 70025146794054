import { Section, SectionBody, SectionHeader } from "./section";
import { CustomCarousel } from "../../common/carousel";

const carouselItems = [
  {
    title: "Cheung Kong Center II",
    description: "TODO: Cheung Kong Centre Description",
    backgroundImage: "/building-images/Cheung Kong Center II.jpeg",
  },
  {
    title: "Grand Lisboa Palace - Macau",
    description: "TODO: Grand Lisboa Palace - Macau Description",
    backgroundImage: "/building-images/Grand Lisboa Palace Macau 1.jpg",
  },
  {
    title: "Queen's Road Centre",
    description: "TODO: Queen's Road Centre Description",
    backgroundImage: "/building-images/Queen's Road Centre.jpeg",
  },
  {
    title: "AXA Southside",
    description: "TODO: AXA Southside Description",
    backgroundImage: "/building-images/AXA Southside.jpeg",
  },
];

export function OurProjects() {
  return (
    <Section id={`our-projects`} additionalClassNames={["section-odd"]}>
      <SectionHeader>Our Projects</SectionHeader>
      <SectionBody>
        <CustomCarousel items={carouselItems} />
      </SectionBody>
    </Section>
  );
}

export function batchItems<T>(list: T[], batchSize: number): T[][] {
  let result: T[][] = [];

  for (let i = 0; i < list.length; i += batchSize) {
    let batch = list.slice(i, i + batchSize);
    result.push(batch);
  }

  return result;
}

export enum MENU_ITEMS {
  HOME = "Home",
  PRODUCTS = "Products",
  PROJECTS = "Projects",
  CONTACT = "Contact",
}

type SubItem = string;

type NavItemType = {
  [key in MENU_ITEMS]: SubItem[] | null;
};

export const NAV_ITEMS: NavItemType = {
  [MENU_ITEMS.HOME]: null,
  [MENU_ITEMS.PRODUCTS]: [
    "Adhesive Glue",
    "Floor Box",
    "Insulation System",
    "Operable Wall",
    "Raised Floor System",
    "Structured Ceiling",
  ],
  [MENU_ITEMS.PROJECTS]: null,
  [MENU_ITEMS.CONTACT]: null,
};

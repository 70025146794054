import Box from "@mui/material/Box";
import { Drawer, IconButton, MenuItem } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import "./navigationDrawer.scss";
import { NAV_ITEMS } from "./const";

interface NavigationDrawerProps {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  closeDrawer: () => void;
}

interface NavigationDrawerItemProps {
  toggleDrawer: () => void;
  itemName: string;
  id: string;
}

export function NavigationDrawer({
  isDrawerOpen,
  toggleDrawer,
  closeDrawer,
}: NavigationDrawerProps) {
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={toggleDrawer}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={{
        sx: {
          width: "100%", // Set width to 100% to take up the full screen
        },
      }}
    >
      <Box className={"navigation-close"}>
        <IconButton onClick={closeDrawer}>
          <CloseIcon fontSize={"large"} />
        </IconButton>
      </Box>
      {/* Drawer content */}
      <Box sx={{ width: 250 }}>
        {Object.keys(NAV_ITEMS).map((itemName, index) => (
          <NavigationDrawerItem
            id={`navigation-drawer-item-${index}`}
            toggleDrawer={toggleDrawer}
            itemName={itemName}
          />
        ))}
      </Box>
    </Drawer>
  );
}

function NavigationDrawerItem({
  toggleDrawer,
  itemName,
  id,
}: NavigationDrawerItemProps) {
  return (
    <MenuItem id={id} onClick={toggleDrawer}>
      {itemName}
    </MenuItem>
  );
}

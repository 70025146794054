import React from "react";
import { Button, Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { OurProducts } from "./ourProducts";
import { OurProjects } from "./ourProjects";

export function Home() {
  return (
    <main>
      <Box id={`section-0`} className={`section`}>
        <Typography component={"h1"} className={`header header-main`}>
          Comprehensive Vertical Integration Solutions
        </Typography>
        <Typography component={"h2"} className={`header header-sub`}>
          From Flooring to Ceiling
        </Typography>
      </Box>

      <OurProducts />
      <OurProjects />

      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Button variant="contained" color="primary" size="large">
          Learn More
        </Button>
      </Container>
    </main>
  );
}

import React from "react";
import { Container, Grid, Link, Typography } from "@mui/material";

export function Footer() {
  return (
    <footer>
      <Container maxWidth="lg" sx={{ paddingBottom: "2rem" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" component="h3">
              Contact Information
            </Typography>
            <Typography variant="body1">Email: info@example.com</Typography>
            <Typography variant="body1">Phone: (123) 456-7890</Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" component="h3">
              Address
            </Typography>
            <Typography variant="body1">1234 Street Name</Typography>
            <Typography variant="body1">City, State, 12345</Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" component="h3">
              Quick Links
            </Typography>
            <Link href="#" variant="body1" display="block">
              Link 1
            </Link>
            <Link href="#" variant="body1" display="block">
              Link 2
            </Link>
            <Link href="#" variant="body1" display="block">
              Link 3
            </Link>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

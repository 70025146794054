import Link from "@mui/material/Link";

import "./headerItemLink.scss";

interface HeaderItemButtonProps {
  item: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export function HeaderItemLink({ item, onClick }: HeaderItemButtonProps) {
  return (
    <Link
      key={item}
      onClick={onClick}
      sx={{ color: "#000", fontSize: "1rem" }}
      className={`header-nav-link`}
    >
      {item}
    </Link>
  );
}
